.test {
  .swiper {
    height: 90vh;
    img {
      width: 100%;
      position: relative;
    }
    .bottom {
      position: relative;

      .content {
        position: absolute;
        right: 12rem;
        bottom: 9rem;
      }
    }
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.swiper-slide-active .slider-title {
  animation: fadeInLeft 1.5s;
}
.slider-title{
  color: gray;
}