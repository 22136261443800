.item {
  //   width: 50%;
  width: 85%;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  display: flex;
  gap: 1rem;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 1rem;
  .right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .image-placeholder {
      text-align: end;
    }
  }
  .order_image{
    width: 80px;
  }
}
