@import "../style/variables";
.best-item {
  // background-color: $light_gray;
  // width: 330px;
  width: 100%;
  display: inline-block;
  border-radius: 15px;
  border: 2px solid $light_gray;
  text-align: center;
  transform: scale(0.9);
  position: relative;
  .main {
    height: 305px;
  }
  .options {
    position: absolute;
    right: 25px;
    top: 35px;
    height: 100%;
    img {
      display: block;
      margin-bottom: 10px;
    }
  }
  .left {
    .title {
      margin-left: -53px;
      font-weight: bold;
      font-size: 17px;
      margin-bottom: 4px;
    }
  }
  .bottom {
    background-color: $light_gray;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 15px;
    .right {
      display: flex;
      flex-direction: column;
      .item-price {
        font-weight: bold;
      }
      .item-discount {
        text-decoration: line-through;
        color: #cbcbcb;
      }
    }
    .rate {
      // background-color: white;
      // display: inline-block;
      // padding: 5px;
      // border-radius: 50%;
      margin-left: 10px;
    }
  }
}
