@import "../style/variables";
.item-card {
  // width: 280px;
  // background-color: $light_gray;
  border-radius: 10px;
  position: relative;
  width: 100%;
  text-align: center;

  cursor: pointer;
  .card-item {
     width: inherit;
    height: 400px;
  }
  .description {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#ffffff00, #000000b8);
    padding-top: 2rem;
    border-radius: 10px;
    transition: all 0s ease;
    opacity: 0;
    .tittle {
      position: absolute;
      top: 45px;
      left: 20px;
      img {
        margin-left: 0px;
        margin-top: 6px;
      }
      h5 {
        color: white;
        font-weight: bold;
        line-height: 10px;
      }
      span {
        color: white;
        margin-right: 3px;
        font-weight: bold;
      }
    }
    .price {
      position: absolute;
      top: 285px;
      left: 20px;
      p {
        color: white;
        .old {
          text-decoration: line-through;
        }
        .new {
          font-weight: bold;
        }
      }
    }
    .cart {
      position: absolute;
      bottom: 35px;
      left: 30px;
      button {
        padding: 5px 10px;
        border-radius: 10px;
        border: none;
        color: white;
        background-color: #601c7e;
        margin-right: 5px;
      }
      img {
        width: 40px;
      }
    }
  }
  &:hover {
    .description {
      opacity: 1;
      transition: all 0.5s ease;
    }
  }
}
.btn-cart {
  z-index: 10;
  transition: all 0.2s ease-in;
  opacity: 1;
}
.btn-cart:hover {
  background-color: #ffb31c !important;
  // color: black !important;
  opacity: 0.8;
}
