@import "../../style/variables";
.signup,
.sign-in,
.forget-password {
  background-color: $light_color;
  margin-bottom: -100px;
  .login-btn {
    padding: 8px 25px;
    border: none;
    background-color: $primary_color;
    color: white;
    border-radius: 10px;
    font-weight: bold;
  }
  .describe {
    margin-left: 5%;
    margin-top: 10%;
    h2 {
      font-weight: bold;
      color: $primary_color;
      margin-top: 10px;
    }
    p {
      color: $primary_color;
    }
  }
}

.sign-in .main-form {
  margin-top: 70px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .sign-in {
    margin-bottom: 0rem;
  }
}
