@import "../../style/variables";
.product {

  .top {
    margin-bottom: 2rem;
    margin-left: 6.6rem;
    .headline {
      display: inline;
      color: $primary_color;
    }
  }
  .box_image {
    // width: 60%;
    margin: 0 auto;
    border-radius: 15px;
  }
  .swiper {
    text-align: center;
    .card_image {
      background-color: #f5f5f5;
      width: 60%;
      margin: 0 auto;
      border-radius: 25px;
    }
  }
  .product_details {
    .headline_details {
      display: flex;
      justify-content: space-between;
      h5 {
        font-weight: bold;
      }
    }
    .subhead {
      display: flex;
      justify-content: space-between;
      span {
        text-decoration: line-through;
        color: #cbcbcb;
        font-size: 1.2rem;
        margin-right: 0.5rem;
      }
    }
    .descrition {
      margin-top: 1rem;
      p {
        font-size: 20px;
      }
    }
    .colors {
      margin-top: 1rem;
      h3 {
        margin-bottom: 1rem;
        font-weight: 500;
        &::before {
          content: "";
          display: inline-block;
          border: 3px solid #ffb31c;
          height: 20px;
          border-radius: 10px;
          margin-right: 0.5rem;
        }
      }
      span {
        margin: 0px;
        margin-right: 1.6rem;
        display: inline-block;
        padding: 1rem;
        border-radius: 50%;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: -5px;
          left: -5px;
          right: -5px;
          bottom: -5px;
          border: #8f8f8f 2px solid;
          border-radius: 50%;
          display: none;
        }
      }
    }
    .size {
      margin-top: 1rem;
      h3 {
        &::before {
          content: "";
          display: inline-block;
          border: 3px solid #ffb31c;
          height: 20px;
          border-radius: 10px;
          margin-right: 0.5rem;
        }
      }
      span {
        border: 2px solid #f5f5f5;
        border-radius: 25px;
        display: inline-block;
        padding: 5px 29px;
        font-size: 18px;
        cursor: pointer;
        &:active {
          border: 2px solid #ffb31c;
        }
      }
    }
    .shippnig {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    .size_guide {
      display: flex;
      justify-content: space-between;
    }
    .options {
      display: flex;
      gap: 1rem;
      button {
        width: 100%;
        border: none;
        text-transform: uppercase;
        border-radius: 10px;
        color: white;
        background-color: $primary_color;
      }
    }
    .disabled {
      cursor: no-drop;
      opacity: 0.5;
    }
  }
}
.max {
  display: block;
}
.small {
  display: none;
}
.active {
  border: 2px solid #ffb31c;
}
.loading{
  margin: 0 auto;
}
@media (max-width: 910px) {
  .card_image {
    width: 100% !important;
  }
  .max {
    display: none;
    img {
      width: 400px;
    }
  }
  .small {
    display: block;
  }
  .product {
    .top {
      margin-left: 0rem;
    }
  }
}
