.verfication{
 display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  height: 350px;
  flex-direction: column;
}
.styles_react-code-input__CRulA > input:focus{
//border: #601C7E ;
  border: 1px solid #601C7E;
  caret-color: white;
  background-color: #601C7E;
  color: white;
}
styles_react-code-input__CRulA > input {
  border: solid 1px #a8adb7;
  border-right: none;
  font-size: 20px;
  color: #525461;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-appearance: initial;
  margin-right: 1rem;
}
.styles_react-code-input-container__tpiKG{
  height: 200px;
}
.styles_react-code-input__CRulA{
  height: auto;
}
.btn-verfiy{
  color: white;
  padding: .5rem;
  border-radius: 10px;
  width:55%;
  background-color:#601C7E;
  outline:none;
  border:none;
  box-shadow: none;
}
.btn:focus{
  box-shadow: none;
}