.arrival-item {
  position: relative;
  .description {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#ffffff00, #000000b8);
    padding-top: 2rem;
    border-radius: 10px;
    transition: all 0s ease;
    opacity: 0;
    .tittle {
      position: absolute;
      top: 45px;
      left: 20px;
      img {
        margin-left: -35px;
        width: 31px;
        height: 31px;
      }
      span {
        color: white;
        font-weight: bold;
      }
      h5 {
        color: white;
        font-weight: bold;
        line-height: 10px;
      }
    }
    .price {
      position: absolute;
      bottom: 60px;
      left: 20px;
      p {
        color: white;
        .old {
          text-decoration: line-through;
        }
        .new {
          font-weight: bold;
        }
      }
    }
    .cart {
      position: absolute;
      bottom: 35px;
      left: 20px;
      button {
        padding: 5px 10px;
        border-radius: 10px;
        border: none;
        color: white;
        background-color: #601c7e;
        margin-right: 5px;
      }
      img {
        width: 40px;
        height: 30px;
      }
    }
  }
  &:hover {
    .description {
      opacity: 1;
      transition: all 0.5s ease;
    }
  }
}
