@import "../../style/variables";
.popup {
  //   width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  backdrop-filter: blur(2px);
  background-color: #00000030;
  z-index: 26;
  display: flex;
  justify-content: center;
  align-items: center;
  .shipping_popup {
    background-color: white;
    width: 50%;
    border-radius: 6px;
    padding: 1.5rem;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      .title {
        color: $primary_color;
        text-transform: uppercase;
        margin-bottom: 0px;
      }
      .times {
        width: 23;
        height: 24;
        cursor: pointer;
      }
    }
  }
}

.active {
}
.hidden {
  display: none;
}
