.circle {
  background-color: #f6f6f6;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30px;
    height: 45px;
  }
}
.active-category {
  border-bottom: 2px solid #601c7e;
}
span {
  margin-left: 5px;
}
