@import "../../style/variables.scss";
.branches {
  .loading{
    margin: 0 auto;
  }
  .branches-top {
    display: flex;
    justify-content: space-between;
    h1 {
      text-transform: uppercase;
    }
  }
  .branches-content {
    .branch-item {
      background-color: $light_color;
      padding: 1.2rem;
      margin-bottom: 1.5rem;
      .branch-heading {
        height: 1.5rem;
        img {
          margin-bottom: 1rem;
        }
        h3 {
          display: inline;
          margin-left: 1rem;
          margin-bottom: -10px;
        }
      }
      .bransh-address {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        p {
          margin-left: 2.5rem;
          color: #8f8f8f;
        }

        .location {
          text-decoration: none;
          color: $primary_color;
          padding: 10px 18px;
          border: none;
          background-color: white;
          border-radius: 10px;

          font-size: 1.1rem;
        }
        img {
          width: 1.4rem;
          margin-left: 10px;
        }
      }
    }
  }
}
