@import "../../style/variables";
.menu {
  background-color: $light_gray;
  height: 40rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 2 0px;
  a {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
    display: inline-block;
    color: black;
    text-decoration: none;

    &.active {
      color: $primary_color;
      font-size: 1.1rem;
      font-weight: bold;
    }
    svg {
      margin-right: 1rem;
    }
  }
  .logout {
    color: red;
  }
}
.personal-info {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    display: flex;
    flex-direction: column;
    h4 {
      align-self: center;
      color: $primary_color;
    }
    .main-form {
      width: 100%;
      align-self: center;
    }
  }
  .span-error {
    margin-left: -40%;
  }
}

.change-password {
  .forget {
    margin-bottom: 0px;
    width: 74%;
    text-align: end;
  }
  .span-error {
    margin-left: -40%;
  }
}

.address {
  padding: 3rem;
  .default-address {
    margin-bottom: 1rem;
  }
  h4 {
    margin-bottom: 1rem;
  }
  .main-address {
    display: flex;
    gap: 9px;
    border: 2px solid $light_gray;
    border-radius: 10px;
    padding: 18px 0px 5px 10px;
    img {
      height: 24px;
    }
    p {
      width: 42%;
      color: $primary_color;
    }
  }
  .all-addresses {
    .other {
      background-color: $light_gray;
      padding: 1rem;
      border-radius: 10px;
      padding: 10px 0px 3px 22px;
      margin-bottom: 1rem;
    }
    p {
      width: 60%;
    }
  }
}

.orders {
  .types {
    display: flex;
    gap: 3rem;
    justify-content: center;
    flex-wrap: wrap;
    a {
      text-decoration: none;

      color: black;
      background-color: $light_gray;
      padding: 0.5rem 1.2rem;
      border-radius: 20px;
    }
    .active {
      background-color: $primary_color;
      color: white;
      font-weight: bold;
    }
  }
  .products {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.order-details {
  .top {
    display: flex;
    gap: 0.5rem;
    img {
      height: 20px;
    }
    .headline {
      color: $primary_color;
      text-transform: uppercase;
      font-weight: 600;
    }
    h6 {
      text-transform: uppercase;
    }
  }

  .accordion-button {
    background-color: #fafafa;
    outline: none;
    border-radius: 15px;
  }
  .accordion-item:first-of-type .accordion-button {
    border: none;
  }
  .accordion-item {
    border: none;
    margin-bottom: 1rem;
    .location-des{
      color: $primary_color;
    }
  }
  .title {
    color: $primary_color;
  }
  .cancel-button {
    background-color: #e40000;
    color: white;
    border: none;
    outline: none;
    display: inline-block;
    width: 28%;
    padding: 0.5rem 0rem;
    border-radius: 10px;
  }
  .order-status-info{
    display: flex;
    justify-content: space-between;
    background-color: #FAFAFA;
    //margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 10px;
    .order_number{
      font-weight: bold;
    }
    .order_status{
      //line-height: 10px;
      margin-bottom: 3.5px;
      font-weight: 500;
    }
    .order_msg{
      color: #707070;
    }
    .location-des{
      color: $primary_color;
    }
  }
}

.modal-header{
  border-bottom: none;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 0;
}
.modal-footer{
  border-top: none;
}
.modal-dialog {
  max-width: 370px;
  margin: 1.75rem auto;
}
.modal-body{
  display: flex;
  flex-direction: column;
  gap: .7rem;
  button{
    border: none;
    padding: .5rem;
    border-radius: 10px;
  }
}
.modal-content{
  border-radius: 25px;
}
.product-accordian{
  .accordion-header{
    margin-top: 1rem ;
    margin-bottom: 1rem ;
  }
  .accordion-body{
    padding: 0;
  }
}

@media (max-width: 991px) {
  .cancel-button {
    width: 100% !important;
  }
  .menu{
    height: 22rem;
    margin-bottom: 1rem;
  }
}
