.swiper-button-next,
.swiper-button-prev {
  color: #ffa81e !important;
}

.active-size {
  border: 2px solid #ffb31c !important;
}
.active-color {
  border : 2px solid #ffb31c !important;
  /* display: none; */
}
