@import "../../style/variables";
.shipping-cart {
  margin-top: 1.5rem;
  .head-line {
    color: $primary_color;
    text-transform: uppercase;
  }
  .cart-items {
    .titles {
      display: flex;
      justify-content: space-between;
      margin-left: 9rem;
      .title-pro {
        margin-left: -8.8rem;
      }
      .title-qty {
        margin-left: 14rem;
      }
    }
    .products {
      .item {
        height: 14vh;
        border: 1px solid #e4e4e4;
        border-radius: 12px;
        // padding: 0.5rem;
        display: flex;
        margin-bottom: 1rem;
        // gap: 8.4rem;
        position: relative;
        justify-content: space-between;
        .image-container {
          display: inline-block;
          background-color: $light_gray;
          width: 6em;
          height: 6.4rem;
          position: relative;
          text-align: center;
          border-radius: 0.8rem;
          img {
            width: 60%;
            height: 100%;
          }
        }
        .info {
          span {
            // margin-left: 1rem;
            font-size: 1.3rem;
          }
        }
        .cart-details {
          align-self: center;
          display: flex;
          width: 57%;
          justify-content: space-between;
          .options {
            margin-left: 7rem;
          }
          .cart-title {
            margin-left: -20%;
          }
          .minus,
          .plus {
            cursor: pointer;
            padding: 0.1rem 0.6rem;
            background-color: $light_gray;
            font-size: 1.5rem;
            border-radius: 5px;
            // font-weight: bold;
          }
          .value {
            font-weight: bold;
            color: #707070;
            margin: 0rem 0.8rem;
          }
        }
        .total {
          align-self: center;
        }
        .delete {
          background-color: $light_gray;
          height: 2.5rem;
          padding: 0.4rem 0.8rem;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 12px;
          cursor: pointer;
          &:hover {
            background-color: #ff0000;
            color: white;
            transition: all 0.5s linear;
          }
        }
      }
    }
  }
}
.cart-checkout {
  background-color: white;
  box-shadow: 0px 3px 16px 0px rgb(128 128 128 / 14%);
  border-radius: 10px;
  margin-left: 4rem;
  padding: 2rem;
  // height: 19rem;
  height: fit-content;
  // background-image: url("../../assets/checkout-image.png");
  h4 {
    text-transform: capitalize;
  }
  .hr-title {
    width: 60%;
    margin: 1rem auto;
  }
  .set-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    h5 {
      text-transform: capitalize;
    }
    span {
      color: $primary_color;
      font-weight: bold;
    }
  }
  .promocode {
    input {
      border: none;
      background-color: $light_gray;
      border-radius: 5px;
      padding: 8px;
      outline: none;
      width: 75%;
    }
    button {
      padding: 0px 10px;
      outline: none;
      border: none;
      background-color: $primary_color;
      color: white;
      border-radius: 5px;
      font-weight: bold;
    }
  }
}
.checkout {
  display: block;
  width: 100%;
  margin: 2rem 0rem;
  border: none;
  padding: 0.5rem;
  border-radius: 7px;
  color: white;
  font-weight: bold;
  background-color: $primary_color;
  text-align: center;
  text-decoration: none;
}
// end shiiping cart

// start delivery address
.delivery-address,
.congratulation {
  .box {
    background-color: #fafafa;
    border-radius: 7px;
    margin-bottom: 20px;
    padding: 11px;
    color: #707070;
  }
  .address-title {
    width: 56%;
  }
  .head-line {
    display: inline;
    color: $primary_color;
    font-weight: bold;
    text-transform: uppercase;
  }
  .higher {
    margin: 0px 0.8rem;
  }
  .second-title {
    display: inline;
    text-transform: uppercase;
    color: #878787;
  }
  input[type="radio"] {
    height: 26px;
    width: 57px;
    margin-left: 16rem;
    color: white;
    transform: translateY(-0.075em);
    &:checked {
      accent-color: #601c7e;
    }
  }

  .add-address {
    display: inline-block;
    width: 100%;
    outline: none;
    border: 1px dashed #707070;
    padding: 10px 10px;
    background-color: white;
    border-radius: 10px;
  }
  .input-address {
    width: 100%;
  }
  .inputs {
    padding: 1rem;
    border-radius: 10px;
    background-color: #fafafa;
    border: none;
    outline: none;
    margin-bottom: 1rem;
  }
  .add-new-address {
    padding: 1rem;
    padding: 13px 3rem;
    border-radius: 10px;
    color: white;
    border: none;
    background-color: $primary_color;
    text-transform: uppercase;
    font-weight: bold;
    outline: none;
  }
  .hidden {
    display: none;
  }
}
// end dilevry address

@media (max-width: 991px) {
  .cart-details {
    width: 48% !important;
    .options {
      margin-left: 0px !important;
    }
  }
  .titles {
    display: none !important;
  }
  .item {
    gap: 0px !important;
    height: 0% !important;
  }
  .cart-checkout {
    margin-left: 0px !important;
    margin-bottom: 2.5rem;
  }
  .address-title {
    width: 100% !important;
    font-size: 13px !important;
  }
  input[type="radio"] {
    height: 18px !important;
  }
}

@media (max-width: 500px) {
  .cart-details {
    flex-direction: column;
    width: 38%;
    justify-content: space-between;
    gap: 5px;
    .cart-title {
      margin-left: 5px !important;
    }
    .options {
      margin-left: 0px !important;
    }
  }
}

@media (max-width: 1024px) {
  .title-qty {
    margin-left: 11rem !important;
  }
}
