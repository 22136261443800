@import "../style/variables";
footer {
  background-image: url(../assets/fooooooooter.png);
  height: 85vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 15rem;
  position: relative;
  .footer-data {
    position: absolute;
    bottom: 50px;
    width: 85%;
    height: 26%;
    .footer-content {
      display: flex;
      .right {
        display: flex;
        justify-content: space-around;
        width: 100%;
        color: white;
        ul {
          list-style: none;
          padding-left: 0rem;
          li {
            margin-bottom: 1rem;
          }
          a {
            color: white;
            text-decoration: none;
            display: block;
            margin-top: 0.5rem;
            &:hover {
              color: black;
            }
          }
        }
      }
    }
  }
  .footer-copy {
    display: flex;
    justify-content: center;
    .footer-copy-details {
      background-color: white;
      border-radius: 10px;
      padding: 0px 10px;
    }
  }
}
.social {
  a {
    cursor: pointer;
  }
}
@media (max-width: 820px) {
  footer {
    width: auto;
    background-position: center;
    margin-top: 0px;
    height: 128vh;
    .subscribe {
      form {
        input {
          width: 100%;
        }
        button {
          position: static;
          margin-top: 1rem;
        }
      }
      width: 100%;
      background-size: cover;

      input {
        width: none;
      }
      .left {
        width: none;
        background-image: none;
        div {
          margin: 0;
          padding-left: 85px;
        }
      }
    }
    .footer-data {
      height: 70%;
      .footer-content {
        flex-wrap: wrap;
        .right {
          flex-wrap: wrap;
          flex-direction: column;
        }
      }
    }
    .footer-copy {
      font-size: 12px;
    }
  }
  .main-form {
    input {
      width: 300px !important;
    }
  }
  .main-form {
    button {
      width: 300px !important;
    }
  }
}
