@import "./variables";
@mixin sectionStart() {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  a {
    text-decoration: none;
    color: $primary_yellow;
    img {
      margin-left: 20px;
    }
  }
}

@mixin newArrivalDesign($x) {
  // background-color: $light_color;
  text-align: center;
  height: 260px;
  border-radius: 20px;
  .arrival-item {
    img {
      height: $x;
    }
  }
}
@mixin loadingComponent (){
  .loading{
    margin: 0 auto;
  }
}
