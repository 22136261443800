@import "../../style/variables";
@import "../../style/mixins";
.categories {
  @include loadingComponent;
  position: relative;
  .category-top {
    background-color: #fafafa;
    .category-type {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      ul {
        display: flex;
        list-style: none;
        gap: 20px;
        margin-left: -30px;
        margin-bottom: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        .active {
          border-bottom: 2px solid $primary_color;
        }
      }
    }
  }
  .flex-base {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    flex-wrap: wrap;
    h1 {
      text-transform: uppercase;
    }
    .filtration {
      span {
        color: $primary_color;
        margin-right: 1.1rem;
        font-size: 1.3rem;
      }
    }
  }
  .category-items {
    margin-bottom: 2rem;
    .flex-items {
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
    }
  }
  .category-content {
    .grid-layout {
      gap: 2rem;
      flex-wrap: wrap;
    }
  }
  .filertPopup {
    position: fixed;
    top: -120px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 120vh;
    display: flex;
    // position: fixed;
    justify-content: end;
    .box {
      background-color: white;
      // height: 20%;
      height: fit-content;
      // width: 29%;
      border-radius: 1rem;
      padding: 1rem;
      margin-top: 15rem;
      margin-right: 9rem;
    }
    .colors {
      padding: 1rem;
      span {
        margin: 0px;
        margin-right: 1.6rem;
        display: inline-block;
        padding: 1rem;
        border-radius: 50%;
        position: relative;
        margin-top: 1rem;
      }
    }
    .sizes {
      padding: 1rem;
      span {
        border: 2px solid #f5f5f5;
        border-radius: 25px;
        display: inline-block;
        padding: 5px 25px;
        font-size: 18px;
        margin-top: 1rem;
      }
    }
    .price-range {
      display: flex;
      justify-content: space-between;
      color: #8c8c8c;
      padding: 0rem 1rem 0rem 1rem;
    }
  }
}
.filertPopup {
  .css-187mznn-MuiSlider-root {
    color: #f8af03 !important;
    height: 13px !important;
  }
  .css-14pt78w-MuiSlider-rail {
    background-color: #e9ecef !important;
  }
  .css-1v5z18m {
    width: 95% !important;
  }
  .options {
    padding: 1rem;
    .apply {
      display: inline-block;
      width: 100%;
      padding: 12px 0px;
      border: none;
      outline: none;
      background-color: $primary_color;
      color: white;
      margin-right: 0.5rem;
      border-radius: 5px;
    }
  }
}
.hiidenPopup {
  display: none !important;
}
.type {
  color: black;
  text-decoration: none;
  transition: color 0.5s ease-in-out;
  &:hover {
    color: $primary_color;
    font-weight: bold;
  }
}

@media (max-width: 678px) {
  .categories {
    .category-top {
      .category-type {
        justify-content: center;
      }
    }
  }
}
