.active a {
  background-color: #601c7e !important;
  border-radius: 50%;
  color: white;
}

.pagination .page-item {
  cursor: pointer;
  border-radius: 50%;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: visible;
  background-color: #fff;
  border-color: #dee2e6;
  cursor: no-drop;
}

.page-link {
  color: black;
  background-color: none;
  border: none;
  font-weight: bold;
  border-radius: 50%;
}
.page-link:hover {
  border-radius: 50%;
  color: black;
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
