.banner {
  background-image: url(../assets/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 15%;
  h4 {
    color: white;
    padding: 5px 0px;
    margin-bottom: 5px;
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .banner {
    background-position: center;
    h4 {
      font-size: 16px;
    }
  }
}
