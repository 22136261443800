.notofication {
  position: fixed;
  left: 0px;
  top: -37px;
  background-color: rgba(0, 0, 0, 0.2);
  // background-color: black;
  height: 110vh;
  width: 100%;
  z-index: 11;
  // opacity: 0.5;
  backdrop-filter: blur(2px);
  display: flex;
  color: black;
  justify-content: end;
  align-items: start;

  .box {
    background-color: white;
    height: 45%;
    width: 18%;
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 9rem;
    margin-right: 9rem;
    .title {
      display: flex;
      justify-content: space-between;
      img {
        height: 1.5rem;
      }
    }
  }
}

.hidden {
  display: none;
}
