.empty {
  height: 25vh;
  .empty-wishlist {
    display: flex;
    justify-content: center;
    margin-top: 9rem;
  }
}
.wishlist {
  .flex {
    gap: 4rem;
    flex-wrap: wrap;
  }
}
