.new-arrivals {
  h1 {
    text-transform: uppercase;
    margin-bottom: 4rem;
  }
  .items-arriavls {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
  }
}
.row-item {
  margin-bottom: 2rem;
}

@media (max-width: 786px) {
  .items-arriavls {
    .item-card {
      width: 100%;
      text-align: center;
    }
  }
}
