@import "../../style/variables";
.forget-password {
  background-color: $light_color;
  height: 65vh;
  .forget-box {
    margin-top: 6%;
    .image-box {
      background-color: white;
      text-align: center;
      height: 25rem;
      padding: 30px;
    }
  }
  .main-form {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .forget-password {
    margin-bottom: 6rem;
  }
}
