@import "../../style/variables";
@import "../../style/mixins";
header {
  // background-color: $light_color;
  @include loadingComponent();
  .top {
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;
      list-style: none;
      gap: 20px;
      margin-left: -30px;
    }
  }
  .bottom {
    // background-image: url(../../assets/headerphoto.png);
    // height: 80.4vh;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .content {
      position: absolute;
      right: 10rem;
      bottom: 5rem;
    }

    h1 {
      color: white;
      font-weight: bold;
      font-size: 105px;
      line-height: 30px;
    }
    p {
      color: white;
      font-size: 60px;
      font-weight: bold;
      letter-spacing: 10px;
    }
    span {
      color: white;
      font-size: 80px;
      display: block;
    }
  }
  .header-slider {
    img {
      width: 100%;
      height: 90vh;
    }
    .swiper {
      height: 90vh;
    }
  }
}

// start categories
.categories {
  .top {
    @include sectionStart();
    h1 {
      font-weight: 700;
      font-size: 30px;
    }
    .types {
      display: flex;
      gap: 30px;
    }
  }
  .cards {
    .row {
      margin-top: 25px;
      margin-bottom: 25px;
      justify-content: space-between !important;
    }
  }
  .items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .third {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 15px;
      .bottom {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  // .headline {
  //   margin: 3rem 0px;
  // }
  .small {
    display: none !important;
  }
}
.small {
  display: none;
}
// end categories
// start best seller
.bestseller {
  margin-top: 4rem;
  margin-bottom: 2rem;
  .top {
    @include sectionStart();
    h1 {
      font-size: 30px;
      font-weight: 700;
    }
  }
}
// end best seller
// start New arrivals
.new-arrivals {
  margin-top: 3rem;
  .top {
    @include sectionStart();
    h1 {
      font-size: 30px;
      font-weight: 700;
    }
  }
}
// .headline {
//   margin-top: 4rem;
//   margin-bottom: 2rem;
// }
.grid-layout {
  display: flex;
  width: 100%;
  .bottom {
    display: flex;
    gap: 1rem;
  }
  .left {
    width: 60%;
  }
  .top {
    justify-content: space-evenly;
    gap: 1rem;
  }
  .right {
    display: flex;
    flex-direction: column;
  }
  .main {
    @include newArrivalDesign(540px);
    background-color: $light_color;
    height: 540px;
  }

  .first {
    @include newArrivalDesign(257px);
    width: 215px;
  }

  .second {
    @include newArrivalDesign(257px);
    width: 215px;
  }

  .third {
    @include newArrivalDesign(257px);
    width: 215px;
  }

  .fourth {
    @include newArrivalDesign(257px);
    width: 215px;
  }
}
.swiper {
  .arrival-item {
    text-align: center;
    img {
      height: 400px;
    }
  }
}
.mobile {
  display: none;
}

// end New arrivals
// start mobile
.mob-sec {
  position: relative;
  margin-top: 200px;
  .mobile-app {
    display: flex;
    width: 100%;
    justify-content: end;
    background-color: $light_color;
    padding: 90px;
    border-radius: 30px;
    background-image: url(../../assets/Rectangle-mobile.png);
    .images {
      position: absolute;
      left: 150px;
      top: -258px;
    }
    .mobile-2 {
      padding-top: 150px;
    }
  }
}
// end mobile
// start subscribe
footer {
  // background-image: url(../../assets/footer-bg.png);
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 20rem;
  position: relative;
  .subscribe {
    margin-top: -8rem;
    position: absolute;
    width: 100%;
    right: 40px;
    z-index: -5;
    h1 {
      color: $primary_color;
      font-size: 23px;
      text-align: center;
    }
    .left {
      // background-image: url(../../assets/girl.png);
      width: 100%;
      height: 50vh;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        margin-left: 135px;
        width: 100%;
      }
    }
    form {
      position: relative;
      text-align: center;
      input {
        background-color: $light_color;
        outline: none;
        border: none;
        padding: 10px;
        border-radius: 20px;
        width: 400px;
        padding-left: 30px;
      }
      button {
        position: absolute;
        // right: 390px;
        padding: 10px 30px;
        border: none;
        border-radius: 20px;
        background-color: $primary_color;
        color: white;
        right: 30rem;
      }
    }
    ::placeholder {
      color: #9da6a5;
    }
  }
}

// end subscribe

// start swiper design
.swiper {
  height: 60vh;
}
.swiper-pagination-bullet {
  background-color: #601c7e !important;
}
.small-screen {
  display: none !important;
}

@media (max-width: 820px) {
  .bottom {
    // height: 30vh !important;
  }
  .content {
    //display: none;
  }
  .categories {
    .top {
      flex-wrap: wrap;
      margin-top: 3rem;
      flex-direction: column;
      .types {
        flex-wrap: wrap;
      }
    }
  }
  header {
    .top {
      flex-wrap: wrap;
      flex-direction: column;
      img {
        width: 15%;
      }
      ul {
        flex-direction: column;
      }
    }
  }
  .new-arrivals {
    .top {
      flex-direction: column;
      align-items: stretch;
    }
    .grid-layout {
      // flex-wrap: wrap;
      flex-direction: column;
      .left {
        width: 100%;
      }

      .top {
        justify-content: stretch;
        flex-wrap: wrap;
      }
      .right {
        width: auto;
      }
      .bottom {
        flex-direction: column;
      }
    }
  }

  .bestseller {
    // margin-top: 18rem;
    .best-item {
      width: 100%;
      // height: 30rem;
    }
    .top {
      flex-direction: column;
      align-items: stretch;
    }
  }
  .categories {
    .top {
      gap: 1rem;
    }
    .card {
      padding-top: 2rem;
    }
    .item-card {
      width: 100%;
      text-align: center;
      padding-left: 0px;
    }
  }
  .mob-sec {
    display: none;
    position: static;
  }
  header {
    height: 80vh;
    .header-slider {
      .swiper {
        height: 85vh;
      }
    }
  }
  .max {
    display: none;
  }
  .small {
    display: block;
  }
  .grid-layout {
    display: none;
  }
  .mobile {
    display: block;
  }
  .tems {
    .third {
      .bootom {
        flex-direction: column;
      }
    }
  }

  .max {
    display: none !important;
  }
  .small-screen {
    display: block !important;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.swiper-slide-active .slider-title {
  animation: fadeInUp 1.5s;
}
