@import "../../style/variables";
.main-form {
  background-color: white;
  border-radius: 10px;
  margin: 15px;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 25px;
  width: 80%;
  input {
    outline: none;
    border: none;
    background-color: $light_color;
    width: 400px;
    padding: 15px 1px;
    border-radius: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;

    color: #9f9f9f;
  }
  button {
    margin: 15px;
    display: block;
    width: 400px;
    padding: 15px 1px;
    border: none;
    background-color: $primary_color;
    color: white;
    text-transform: uppercase;
    border-radius: 12px;
    margin: 0 auto;
    margin-top: 10px;
  }
  span {
    margin-top: 10px;
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #9f9f9f;
  }
  .social {
    display: flex;
    justify-content: center;
    gap: 15px;
    img {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
    }
  }
}
::placeholder {
  text-transform: capitalize;
}

.error {
  border: 1px solid #dc3545 !important;
}
.span-error {
  color: red !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-size: 12px;
  margin-left: -60%;
}

@media (max-width: 768px) {
  .main-form {
    width: 100%;
    margin: 0;
  }
  .signup {
    margin-bottom: 0rem;
    height: 56rem;
  }
}
