.order-item {
  display: flex;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 1rem;
  gap: 1rem;
  .image {
    width: 75px;
    height: 75px;
  }
  .right {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .quantity {
    color: #ffb31c;
    font-weight: 700;
  }
}
