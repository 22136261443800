@import "../style/variables";
nav {
  background-color: white !important;
  navbar{

  }
  .navbar-collapse {
    flex-grow: 0;
    align-items: center;
  }
  .logo {
    margin-right: 5rem !important;
  }
  #home {
    // background-image: url(../assets/Rectanglehome.png);
    height: 70px;
    border-bottom-right-radius: 13px;
    padding-top: 23px;
  }
  .options {
    display: flex;
    gap: 15px;
    align-items: center;
  }
}
.navbar {
  padding-top: 0px;
}
.navbar-nav {
  align-items: center;
  gap: 10px;
}
.navbar {
  margin-top: -7px;
  .container {
    flex-direction: row-reverse;
  }
}
.navbar-light .navbar-nav .nav-link.active {
  background-image: url(../assets/Rectanglehome.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
  padding-top: 20px;
  color: white;
}
.nav-color {
  color: $primary_color !important;
}

.bg-light {
  --bs-bg-opacity: 0;
}

.navbar-light .navbar-nav .nav-link {
  // color: rgba(0, 0, 0, 0.55);
  color: black;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  transition: all 0.1s linear;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: $primary_color;
}
.container-search {
  position: absolute;
  z-index: 3;
  top: 50px;
  background-color: white;
  width: 13%;
  div {
    border: 1px solid gray;
    padding: 0.2rem;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}
@media (max-width: 768px) {
  #home {
    background-image: none !important;
    color: $primary_color !important;
    padding-top: 0px !important;
    padding-top: 35px !important;
  }
  nav {
    padding: 10px 0px;
  }
  .options {
    gap: 0px !important;
  }
  nav {
    .logo {
      margin-right: 0px !important;
    }
  }
  .bg-light {
    --bs-bg-opacity: 1;
  }
  .logo {
    img {
      width: 100px;
    }
  }
}
