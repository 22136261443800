@import "../../style/mixins";
@import "../../style/variables";
.content-us {
  background-color: $light_gray;
  .left {
    padding-left: 6rem;
    padding-top: 7rem;
    img {
      margin-top: 3rem;
    }
    h3 {
      margin-top: 2rem;
      color: $primary_color;
    }
    p {
      color: $primary_color;
      margin-top: 0.5rem;
    }
  }
  .right {
    padding: 4rem;
    .call {
      background-color: white;
      height: 100%;
      text-align: center;
      padding: 2rem;
      border-radius: 20px;
      .call-title {
        color: #8f8f8f;
        margin-bottom: 0px;
        margin-left: -10px;
      }
      .call-number {
        color: $primary_color;
      }
    }
    .number {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      width: 79%;
      gap: 1.2rem;

      img {
        height: 50px;
      }
    }
    .email {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      width: 102%;
      gap: 1.2rem;
      margin-top: 1rem;
      .email-title {
        color: #8f8f8f;
      }
      .email-content {
        color: $primary_color;
      }
      img {
        height: 50px;
      }
    }
    .social {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
    }
  }
}

@media (max-width: 910px) {
  .content-us {
    margin-bottom: 3rem;
    .left {
      padding-top: 0px;
      padding-left: 3rem;
    }
    .right {
      padding: 2rem;
    }
  }
}
